import { SelectableListItem } from '@app/common'
import { OnboardingFormData } from '@app/form'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import clsx from 'clsx'
import { FormikProps } from 'formik'
import { pxToRem } from 'material/theme'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UnassignedTemplateList } from '../dialog/assignTemplate/UnassignedTemplateList'

export interface OnboardingProfileProps {
    formikProps: FormikProps<OnboardingFormData>
    status: string
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        alignItems: 'center',
        flexDirection: 'column',
        gap: theme.spacing(3),
        textAlign: 'center',
        width: '100%',
        paddingTop: theme.spacing(4)
    },
    listContainer: {
        maxWidth: 555,
        width: '100%',
        height: 440,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: 15,
        padding: theme.spacing(3, 4),
        display: 'grid',
        gridAutoRows: 'min-content',
        gap: theme.spacing(2.5),
        '& .worksheetNavigation__listItem': {
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,
            boxShadow:
                '0px 15px 47px rgba(0, 0, 0, 0.035), 0px 1.87823px 5.88513px rgba(0, 0, 0, 0.07)'
        }
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'center',
        height: 40
    },
    message: {
        fontSize: pxToRem(16),
        margin: theme.spacing(1, 'auto'),
        color: theme.palette.text.hint
    },
    errorMessage: {
        color: theme.palette.error.main
    },
    information: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(),
        justifyItems: 'center',
        textAlign: 'center'
    },
    content: {
        display: 'grid',
        gap: theme.spacing(),
        justifyItems: 'center'
    },
    title: {
        margin: 0
    },
    description: {
        margin: 0,
        lineHeight: '150%',
        maxWidth: 390
    }
}))

export const OnboardingProfile: React.FC<OnboardingProfileProps> = ({
    formikProps,
    status
}) => {
    const { t } = useTranslation(['common', 'matrices'])
    const classes = useStyles()

    const [selectedTemplates, setSelectedTemplates] = React.useState<
        Array<SelectableListItem>
    >([])
    const numberOfSelectedTemplates = formikProps.values.templates.filter(
        (template) => template.selected
    ).length

    const handleTemplateListChange = (id: string) => {
        const templatesWithUpdatedSelection = selectedTemplates.map(
            (template) =>
                template.id === id
                    ? { ...template, selected: !template.selected }
                    : template
        )

        setSelectedTemplates(templatesWithUpdatedSelection)

        return templatesWithUpdatedSelection
    }

    useEffect(() => {
        setSelectedTemplates(formikProps.values.templates)
    }, [formikProps])

    return (
        <div className={classes.container}>
            <div className={classes.information}>
                <h2 className={classes.title}>
                    {t('matrices:onboarding.secondStep.title')}
                </h2>

                <p className={classes.description}>
                    {t('matrices:onboarding.secondStep.description')}
                </p>
            </div>

            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.listContainer,
                        'modern-scrollbar'
                    )}
                >
                    <UnassignedTemplateList
                        formikProps={formikProps}
                        error={status === 'rejected'}
                        searchKey={formikProps.values.searchKey}
                        handleTemplateListChange={handleTemplateListChange}
                        listItems={formikProps.values.templates}
                        isEmpty={formikProps.values.templates.length === 0}
                        withSeparator={false}
                    />
                </div>

                <div className={classes.messageContainer}>
                    {formikProps.errors.templates && (
                        <p
                            className={clsx(
                                classes.message,
                                classes.errorMessage
                            )}
                        >
                            {formikProps.errors.templates}
                        </p>
                    )}

                    {numberOfSelectedTemplates > 0 && (
                        <p className={classes.message}>
                            {t('matrices:assignTemplate.selectedTemplates', {
                                count: numberOfSelectedTemplates
                            })}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OnboardingProfile

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import animatedCheckmark from '../../assets/images/animated-checkmark.svg'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridAutoRows: 'min-content',
        gap: theme.spacing(4),
        justifyItems: 'center',
        alignItems: 'center',
        maxWidth: 350,
        paddingTop: theme.spacing(23)
    },
    checkmark: {
        background: `url(${animatedCheckmark})`,
        height: 100,
        width: 100
    },
    content: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(),
        justifyItems: 'center',
        textAlign: 'center'
    },
    title: {
        margin: 0
    },
    description: {
        margin: 0,
        lineHeight: '150%'
    }
}))

export const OnboardingFinish: React.FC = () => {
    const { t } = useTranslation(['common', 'matrices'])
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div
                className={classNames(
                    classes.checkmark,
                    'animated-checkmark play'
                )}
            />

            <div className={classes.content}>
                <h2 className={classes.title}>
                    {t('matrices:onboarding.thirdStep.title')}
                </h2>

                <p className={classes.description}>
                    {t('matrices:onboarding.thirdStep.description')}
                </p>
            </div>
        </div>
    )
}

export default OnboardingFinish
